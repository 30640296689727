import request from "@/utils/request";
// 添加商品
const addGoods = (params) =>
  request.post("/boxing/web/shopping/addGoods", params);
// 订单列表
const getOrderList = (params) =>
  request.post("/boxing/web/shopping/pageQueryGoodsList", params);
// 获取快递公司信息
const getExpressList = () => request.post("/boxing/common/queryExpressCompany");
// 订单发货
const getDelivery = (params) =>
  request.post("/boxing/web/shopping/deliveryGoods", params);
// 修改物流单号
const editCode = (params) =>
  request.post("/boxing/web/shopping/modifyDeliveryInfo", params);
// 查看物流
const checkExpress = (expressNo, expressCode) =>
  request.get(`/boxing/common/getExpressInfo/${expressNo}/${expressCode}`);
// 代理商列表
const getAgentList = (params) =>
  request.post("/boxing/web/saleAgent/saleAgentList", params);
// 添加修改代理商
const setAgent = (params) =>
  request.post("/boxing/web/saleAgent/operationSaleAgent", params);
// 修改代理商状态
const setAgentStatus = (params) =>
  request.post("/boxing/web/saleAgent/modifySaleAgentStatus", params);
// 删除代理商
const deleteAgent = (params) =>
  request.post("/boxing/web/saleAgent/deleteSaleAgent", params);
// 根据代理商id查询销售代理商品信息
const getAgentGoods = (params) =>
  request.post("/boxing/web/saleAgent/querySaleAgentGoodsInfo", params);
// 代理佣金列表
const getCommission = (params) =>
  request.post("/boxing/web/saleAgent/pageSaleAgentCommission", params);
// 修改佣金状态
const editCommissionStatus = (params) =>
  request.post("/boxing/web/saleAgent/modifyCommissionStatus", params);
// 修改佣金显隐
const setShowStatus = (params) =>
  request.post("/boxing/web/saleAgent/modifyCommissionShowStatus", params);
export {
  getOrderList,
  getExpressList,
  getDelivery,
  checkExpress,
  getAgentList,
  setAgent,
  getCommission,
  deleteAgent,
  editCommissionStatus,
  addGoods,
  editCode,
  setShowStatus,
  setAgentStatus,
  getAgentGoods,
};
