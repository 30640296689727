<template>
  <div class="agent">
    <div class="top">
      <div class="querybox">
        <el-row :gutter="20">
          <el-col :span="6">
            <p>代理商昵称:</p>
            <el-input
              v-model="queryParams.agentNickname"
              placeholder="请输入代理商昵称"
            ></el-input>
          </el-col>
          <el-col :span="6">
            <p>代理商电话:</p>
            <el-input
              v-model="queryParams.agentPhone"
              placeholder="请输入代理商电话"
            ></el-input>
          </el-col>
        </el-row>
      </div>
      <div class="btnbox">
        <el-button
          type="primary"
          icon="el-icon-refresh-right"
          class="gray-btn"
          @click="resetFn"
          >重置</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-search"
          class="blue-btn"
          @click="search"
          >查询</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-s-tools"
          class="green-btn"
          @click="goAdd"
          >添加代理商</el-button
        >
      </div>
    </div>
    <div class="content">
      <el-table
        :data="tableData"
        tooltip-effect="dark"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column prop="musicCover" label="头像">
          <template slot-scope="{ row }">
            <div class="logo">
              <img :src="row.headImage" alt="" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="agentNickname" label="代理商昵称">
        </el-table-column>
        <el-table-column prop="agentPhone" label="代理商电话">
        </el-table-column>
        <el-table-column prop="agentLabel" label="代理商标签">
        </el-table-column>
        <el-table-column prop="level" label="等级">
          <template slot-scope="{ row }">
            {{
              row.level === 1
                ? "一级代理商"
                : row.level === 2
                ? "二级代理商"
                : "三级代理商"
            }}</template
          >
        </el-table-column>
        <el-table-column prop="parentNickname" label="上级代理商">
          <template slot-scope="{ row }">
            {{ row.parentNickname ? row.parentNickname : "-" }}</template
          >
        </el-table-column>
        <el-table-column prop="grandParentNickname" label="上上级代理商">
          <template slot-scope="{ row }">
            {{
              row.grandParentNickname ? row.grandParentNickname : "-"
            }}</template
          >
        </el-table-column>
        <el-table-column prop="commissionRate" label="代理商品">
          <template slot-scope="{ row }">
            <el-button class="textblue-btn" @click="checkList(row.id)">
              查看
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-button
              class="blue-btn"
              @click="goEdit(row)"
              style="margin-right: 5px"
              >修改
            </el-button>
            <!-- <el-popconfirm
              title="确认删除该代理商吗？"
              popper-class="customerdel-popover"
              @confirm="cancelOrder(scope.row.id)"
            >
              <el-button class="textred-btn" slot="reference">
                 删除
              </el-button>
            </el-popconfirm> -->
            <el-popconfirm
              title="是否启用该代理商？"
              popper-class="customerdel-popover"
              @confirm="cancelOrder(row, 0)"
              v-if="Number(row.status) === 1"
            >
              <el-button class="textblue-btn" slot="reference">启用</el-button>
            </el-popconfirm>
            <el-popconfirm
              title="是否禁用该代理商？"
              popper-class="customerdel-popover"
              @confirm="cancelOrder(row, 1)"
              v-if="Number(row.status) === 0"
            >
              <el-button class="textred-btn" slot="reference">禁用</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <pagination
        v-show="total > queryParams.pageSize"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="init"
      />
    </div>
    <!-- 添加代理商 -->
    <el-dialog
      :visible.sync="addVisible"
      width="40%"
      :before-close="handleaddClose"
      destroy-on-close
      :show-close="false"
      top="10vh"
    >
      <div slot="title" class="title">
        <p>{{ setStatus === "add" ? "添加代理商" : "编辑代理商" }}</p>
        <span @click="handleaddClose"><i class="el-icon-close"></i></span>
      </div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
        label-position="top"
        hide-required-asterisk
      >
        <el-form-item label="代理商昵称" prop="agentNickname">
          <el-input
            v-model="ruleForm.agentNickname"
            placeholder="请输入代理商昵称"
          ></el-input>
        </el-form-item>
        <el-form-item label="代理商手机号" prop="agentPhone">
          <el-input
            v-model="ruleForm.agentPhone"
            placeholder="请输入代理商手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="代理商标签" prop="agentLabel">
          <el-input
            v-model="ruleForm.agentLabel"
            placeholder="请输入代理商标签"
          ></el-input>
        </el-form-item>
        <el-form-item label="上级代理商电话" v-if="setStatus === 'add'">
          <el-input
            v-model="ruleForm.parentPhone"
            placeholder="请输入上级代理商电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="代理商品" class="rhythm-box">
          <el-row class="title-row">
            <el-col :span="5">商品封面</el-col>
            <el-col :span="5">商品名称</el-col>
            <el-col :span="4">商品售价</el-col>
            <el-col :span="5">出厂价格</el-col>
            <el-col :span="5">代理价格</el-col>
          </el-row>
          <el-row
            v-for="(item, index) in goodsList"
            :key="index"
            class="item-box"
          >
            <el-col :span="5">
              <el-form-item>
                <img :src="item.goodsCover" alt="" />
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item>
                <p>{{ item.goodsName }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item>
                <p>{{ item.goodsPrice }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item>
                <el-input
                  v-model="item.factoryPrice"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item>
                <el-input
                  v-model="item.agencyPrice"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
        <span class="tips">#代理价格不可低于出厂价格</span>
        <el-form-item>
          <div class="btn-box">
            <el-button
              @click="resetForm('ruleForm')"
              type="primary"
              class="gray-btn"
              >取消</el-button
            >
            <el-button
              type="primary"
              @click="submitForm('ruleForm')"
              class="blue-btn"
              >确定</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 查看代理商品 -->
    <el-dialog
      :visible.sync="checkVisible"
      width="50%"
      :before-close="handlecheckClose"
      destroy-on-close
      :show-close="false"
      top="10vh"
    >
      <div slot="title" class="title">
        <p>查看代理商品</p>
        <span @click="handlecheckClose"><i class="el-icon-close"></i></span>
      </div>
      <el-table
        :data="goodsList.filter((el) => el.agencyPrice && el.factoryPrice)"
        tooltip-effect="dark"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        style="margin-top: 20px"
      >
        <el-table-column prop="musicCover" label="商品封面">
          <template slot-scope="{ row }">
            <img :src="row.goodsCover" alt="" style="width: 100px" />
          </template>
        </el-table-column>
        <el-table-column prop="goodsName" label="商品名称"> </el-table-column>
        <el-table-column prop="goodsPrice" label="商品售价"> </el-table-column>
        <el-table-column prop="factoryPrice" label="出厂价格">
        </el-table-column>
        <el-table-column prop="agencyPrice" label="代理价格"> </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import {
  getAgentList,
  setAgent,
  setAgentStatus,
  getAgentGoods,
} from "@/api/goods";
export default {
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        agentNickname: "",
        agentPhone: "",
      },
      tableData: [],
      total: 0, //总数
      setStatus: "add",
      addVisible: false,
      ruleForm: {
        agentNickname: "",
        agentPhone: "",
        agentLabel: "",
        parentPhone: "",
      },
      rules: {
        agentNickname: [
          { required: true, message: "请输入代理商昵称", trigger: "blur" },
        ],
        agentPhone: [
          { required: true, message: "请输入代理商手机号", trigger: "blur" },
        ],
        agentLabel: [
          { required: true, message: "请选择代理商等级", trigger: "change" },
        ],
        commissionRate: [
          { required: true, message: "请输入代理商佣金比例", trigger: "blur" },
        ],
      },
      goodsList: [],
      checkVisible: false,
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    //获取代理商列表
    async init() {
      const res = await getAgentList(this.queryParams);
      this.tableData = res.result.records;
      this.total = res.result.total;
    },
    // 查询
    search() {
      this.queryParams.pageNum = 1;
      this.init();
    },
    //重置
    resetFn() {
      this.queryParams.agentPhone = "";
      this.queryParams.agentNickname = "";
      this.queryParams.pageNum = 1;
      this.init();
    },
    // 删除代理商
    // async cancelOrder(id) {
    //   await deleteAgent({ id });
    //   this.$message.success("代理商删除成功");
    //   this.init();
    // },
    // 启用禁用
    async cancelOrder(item, status) {
      const params = {
        status: status,
        id: item.id,
      };
      await setAgentStatus(params);
      // this.$refs[`${text}-popover-${index}`].doClose();
      this.init();
    },
    // 新增
    async goAdd() {
      this.setStatus = "add";
      const res = await getAgentGoods({ id: 0 });
      this.goodsList = res.result;
      this.addVisible = true;
    },
    handleaddClose() {
      this.addVisible = false;
    },
    handlecheckClose() {
      this.checkVisible = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // alert("submit!");
          const { agentLabel, agentNickname, agentPhone, id, parentPhone } =
            this.ruleForm;
          const goodsListNew = this.goodsList.filter(
            (el) => el.factoryPrice && el.agencyPrice
          );
          if (goodsListNew.length > 0) {
            goodsListNew.map((el) => {
              el.factoryPrice = Number(el.factoryPrice);
              el.agencyPrice = Number(el.agencyPrice);
            });
          }
          // console.log("goodsListNew,goosList :>> ", goodsListNew, goodsList);
          if (this.setStatus === "add") {
            const params = {
              agentLabel,
              agentNickname,
              agentPhone,
              goodsList: goodsListNew,
              parentPhone,
            };
            await setAgent(params);
            this.resetForm("ruleForm");
            this.$message.success("添加代理商成功！");
            this.search();
            this.addVisible = false;
          } else {
            const params = {
              agentLabel,
              agentNickname,
              agentPhone,
              goodsList: goodsListNew,
              parentPhone,
              id,
            };
            await setAgent(params);
            this.resetForm("ruleForm");
            this.$message.success("编辑代理商成功！");
            this.search();
            this.addVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.addVisible = false;
    },
    // 编辑
    async goEdit(row) {
      this.ruleForm = row;
      this.setStatus = "edit";
      const res = await getAgentGoods({ id: row.id });
      this.goodsList = JSON.parse(JSON.stringify(res.result));
      this.addVisible = true;
    },
    //查看代理商品
    async checkList(id) {
      // console.log("id :>> ", id);
      const res = await getAgentGoods({ id });
      // console.log("res :>> ", res);
      this.goodsList = res.result;
      this.checkVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.agent {
  .btnbox-agent {
    display: flex;
    justify-content: flex-end;
    .el-button {
      width: 100px;
    }
  }
  .top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #0000000d;
    .querybox {
      width: 90%;
      margin-bottom: 20px;
      ::v-deep .el-row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .el-col {
          display: flex;
          align-items: center;
          p {
            color: #4e5969;
            line-height: 22px;
            margin-right: 16px;
            white-space: nowrap;
          }
          .el-input,
          .el-range-editor {
            width: 256px;
          }
          input::-webkit-input-placeholder {
            /* WebKit browsers */
            color: #86909c;
          }
          .el-input__inner,
          .el-range-input {
            background: #f7f8fa;
            border: none;
            color: #86909c;
          }
          .tabul {
            margin: 16px 0;
            li {
              margin-right: 24px;
              white-space: nowrap;
            }
          }
        }
      }
    }
    .btnbox {
      display: flex;
      .el-button {
        padding: 11px 18px;
        // width: 84px;
        width: fit-content;
        margin-right: 12px;
        height: fit-content;
      }
    }
  }
  .content {
    padding-top: 20px;
    .logo {
      display: flex;
      justify-content: center;
      img {
        width: 50px;
        height: 50px;
        border-radius: 5px;
      }
    }
    .blue-btn {
      // padding: 10px 0;
      height: 30px;
      line-height: 30px;
      padding: 0 10px;
    }
  }
  ::v-deep .el-dialog__body {
    padding: 0 43px 27px 50px;
    .title-row {
      background: #f5f6f6;
      margin-top: 20px;
      border-radius: 2px;
      height: 32px;
      .el-col-4,
      .el-col-5 {
        text-align: center;
        height: 32px;
        font-size: 14px;
        color: #696969;
        line-height: 32px;
      }
    }
    .item-box {
      margin-top: 16px;
      display: flex;
      align-items: center;
      // border: 2px solid #999999;
      .el-col-4,
      .el-col-5 {
        text-align: center;
        font-size: 14px;
        color: #696969;
        line-height: 32px;
      }
      img {
        width: 100px;
      }
      p {
        font-size: 16px;
        color: #333333;
        line-height: 20px;
      }
      .el-form-item {
        margin-top: 0;
        .el-form-item__content {
          // text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .el-col-5 {
        display: flex;
        align-items: center;
        justify-content: center;

        .el-input {
          width: 120px !important;
          text-align: center;
          .el-input__inner {
            text-align: center;
            height: 32px;
            line-height: 32px;
          }
          .el-input__icon {
            height: 32px;
            line-height: 32px;
          }
        }
      }
    }
    .demo-ruleForm {
      .el-form-item {
        margin-top: 30px;
        .el-input,
        .el-range-editor,
        .el-select {
          width: 100%;
          display: flex;
          .el-input__suffix {
            .el-input__suffix-inner {
              display: flex;
              align-items: center;
              height: 100%;
              font-size: 15px;
              color: #333333;
              line-height: 21px;
            }
          }
        }
        input::-webkit-input-placeholder {
          /* WebKit browsers */
          color: #86909c;
          font-weight: normal;
        }
        .el-input__inner,
        .el-range-input {
          background: #f7f8fa;
          border: none;
          font-size: 16px;
          color: #333333;
          line-height: 22px;
        }
      }
      .el-form-item__label {
        font-size: 15px;
        color: #333333;
        line-height: 21px;
        padding-bottom: 10px;
      }
      .el-form-item__error {
        font-size: 15px;
        line-height: 21px;
      }
      .tips {
        font-size: 15px;
        color: #a0a3a3;
        line-height: 21px;
      }
      .btn-box {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .el-button {
          width: 105px;
          border-radius: 6px;
          padding: 11px 0;
        }
      }
    }
  }
}
</style>
